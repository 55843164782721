import React from 'react';
import { styled } from '@mui/material/styles';
import { ButtonBase, Box, Grid } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import KingBedIcon from '@mui/icons-material/KingBed';
import { useTranslation } from 'next-i18next';

import IconBike from 'views/common/components/UI/Icons/IconBike';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';

const PREFIX = 'PackageRadioButton';

const classes = {
  card: `${PREFIX}-card`,
  packageTitle: `${PREFIX}-packageTitle`,
  active: `${PREFIX}-active`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  pricePerPerson: `${PREFIX}-pricePerPerson`,
  packageOptions: `${PREFIX}-packageOptions`,
  optionItem: `${PREFIX}-optionItem`,
  packageOptionsIcon: `${PREFIX}-packageOptionsIcon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme, offRoadPackages }) => ({
  [`& .${classes.card}`]: {
    backgroundColor: theme.palette.grey.A000,
    borderRadius: 5,
    border: `1px solid ${theme.palette.grey['200']}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.packageTitle}`]: {
    padding: offRoadPackages ? theme.spacing(2) : theme.spacing(0.75, 1.25),
    width: '100%',
  },

  [`& .${classes.active}`]: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.A000,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.05)',
  },

  [`& .${classes.icon}`]: {
    position: 'absolute',
    top: offRoadPackages ? theme.spacing(2) : theme.spacing(1),
    left: offRoadPackages ? theme.spacing(2) : theme.spacing(1),
    '&.active': {
      color: theme.palette.primary.main,
    },
  },

  [`& .${classes.title}`]: {
    maxWidth: `calc(100% - ${theme.spacing(3.75)})`,
    fontSize: offRoadPackages
      ? theme.typography.pxToRem(16)
      : theme.typography.pxToRem(14),
    marginLeft: theme.spacing(3.75),
  },
  [`& .${classes.pricePerPerson}`]: {
    fontSize: offRoadPackages
      ? theme.typography.pxToRem(16)
      : theme.typography.pxToRem(14),
  },

  [`& .${classes.packageOptions}`]: {
    backgroundColor: theme.palette.grey['50'],
    padding: theme.spacing(0.75, 1.25),
    width: '100%',
    flexGrow: 1,
    display: offRoadPackages ? 'none' : 'flex',
    '&.active': {
      backgroundColor: theme.palette.orange[100],
      '& .PackageRadioButton-optionItem': {
        color: theme.palette.secondary.main,
      },
    },
  },

  [`& .${classes.optionItem}`]: {
    flexBasis: 'auto',
    color: theme.palette.grey[400],
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
  },

  [`& .${classes.packageOptionsIcon}`]: {
    fontSize: theme.typography.pxToRem(16),
    verticalAlign: 'text-bottom',
    marginRight: theme.spacing(0.5),
  },
}));

const PackageRadioButton = (props) => {
  const {
    index,
    id,
    active,
    pricePerPerson,
    adultsNumber,
    numberOfMoto,
    numberOfBed,
    onClick,
    offRoadPackages = false,
    packageName = null,
    showPriceOnly = false,
  } = props;

  const { t } = useTranslation('fe_er_tour_package_selector');

  return (
    <Root offRoadPackages={offRoadPackages}>
      <ButtonBase
        component="label"
        className={`${classes.card} ${active ? classes.active : ''}`}
        onClick={() => onClick(id)}
        id={id}>
        <Box className={classes.packageTitle}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Box className={`${classes.icon} ${active ? 'active' : ''}`}>
                {active ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
              </Box>
              <Typography500 variant="h5" className={classes.title}>
                {packageName
                  || t('fe_er_tour_package_selector:package', {
                    number: index + 1,
                  })}
              </Typography500>
            </Grid>
            <Grid item xs={5} align="right">
              <Typography500 variant="body2" className={classes.pricePerPerson}>
                {showPriceOnly
                  ? pricePerPerson
                  : t('fe_er_tour_package_selector:per_person', {
                    price: pricePerPerson,
                  })}
              </Typography500>
            </Grid>
          </Grid>
        </Box>
        <Box className={`${classes.packageOptions} ${active && 'active'}`}>
          <Grid container spacing={1}>
            <Grid item xs className={classes.optionItem}>
              <Typography500 variant="caption">
                {adultsNumber > 1 ? (
                  <>
                    <PeopleIcon className={classes.packageOptionsIcon} />
                    {t('fe_er_tour_package_selector:person.many', {
                      number: adultsNumber,
                    })}
                  </>
                ) : (
                  <>
                    <PersonIcon className={classes.packageOptionsIcon} />
                    {t('fe_er_tour_package_selector:person.one', {
                      number: adultsNumber,
                    })}
                  </>
                )}
              </Typography500>
            </Grid>
            {numberOfMoto && numberOfMoto > 0 && (
              <Grid item xs className={classes.optionItem}>
                <Typography500 variant="caption">
                  <IconBike className={classes.packageOptionsIcon} />
                  {t('fe_er_tour_package_selector:motorcycles', {
                    motor_number: numberOfMoto,
                  })}
                </Typography500>
              </Grid>
            )}
            {numberOfBed && numberOfBed > 0 && (
              <Grid item xs className={classes.optionItem}>
                <Typography500 variant="caption">
                  <KingBedIcon className={classes.packageOptionsIcon} />
                  {t('fe_er_tour_package_selector:room', {
                    bed_number: numberOfBed,
                  })}
                </Typography500>
              </Grid>
            )}
          </Grid>
        </Box>
      </ButtonBase>
    </Root>
  );
};

export default PackageRadioButton;
